"use client";
import { useSite } from "src/app/hooks/use-site";
import { NiloImage } from "@nilo/design";

export default function Header() {
  const { site } = useSite();

  return (
    <div className="relative  bg-slate-50 pb-8 pt-4">
      <div className="flex h-10 max-h-[40px] justify-center">
        {!!site?.logo && (
          <NiloImage
            alt={`${site?.name} logo`}
            height={40}
            src={site?.logo}
            width={80}
          />
        )}
      </div>
      <div className="bg-nilogray-200 absolute top-[72px] h-4 w-full rounded-t-2xl shadow-inner"></div>
    </div>
  );
}
